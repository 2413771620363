/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { Route, Redirect, RouteComponentProps, RouteProps } from 'react-router-dom';

import Layout from 'components/Layout';

interface Props extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  transparentHeader?: boolean;
  redirectTo?: string;
  withSidebarTabletAndMobile?: boolean;
  withHeader?: boolean;
  screen: string;
  showCsat?: boolean;
  showBgImg?: boolean;
}

function RouteItem({
  redirectTo,
  component,
  transparentHeader = false,
  withSidebarTabletAndMobile,
  withHeader,
  screen,
  showCsat = true,
  showBgImg = true,
  ...config
}: Props) {
  return redirectTo ? (
    <Redirect to={redirectTo} />
  ) : (
    <Route
      {...config}
      render={routeProps => (
        <Layout
          transparentHeader={transparentHeader}
          withSidebarTabletAndMobile={withSidebarTabletAndMobile}
          withHeader={withHeader}
          component={component}
          screen={screen}
          showCsat={showCsat}
          showBgImg={showBgImg}
          {...routeProps}
        />
      )}
    />
  );
}

export default RouteItem;

/* eslint-disable */

interface mixPanelDictionary {
  [key: string]: string;
}

export const mixPanelDictionary: mixPanelDictionary = {
 'download_excel': 'Descarga de excel pedidos',
 'showDetails': 'Ver detalles',
 'createOC': 'Ingreso nueva OC',
 'showProforma': 'Ver proforma',
 'showCertificate': 'Ver certificados',
 'showDispatches': 'Ver despachos',
 'showPackingList': 'Ver packing List',
 'showActivities': 'Ver actividad',
 'confirmOc': 'Confirmar OC'
};

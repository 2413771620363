/* eslint-disable complexity */
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { useToasts } from 'react-toast-notifications';

import { useDispatch, useSelector } from 'contexts/NotificationsContext';
import {
  useDispatch as useDispatchSidebar,
  useSelector as useSelectorSidebar
} from 'contexts/SidebarContext';
import { useSelector as useSelectorUser } from 'contexts/UserContext';
import { actionCreators as notificationsActionCreators } from 'contexts/NotificationsContext/reducer';
import Loading from 'components/Loading';
import Notification from 'components/Notification';
import PATHS from 'components/Routes/paths';
import Tooltip from 'components/Tooltip';
import { useLazyRequest } from 'hooks/useRequest';
import { notificationWithoutAlert, readNotification } from 'services/NotificationService';
import { IPostNotificationWithoutAlert, NotificationType } from 'types/Notifications';
import { getNewNotifications } from 'utils/notifications';
import { actionCreators as actionCreatorsSidebar } from 'contexts/SidebarContext/reducer';
import Alert from 'components/Alert';
import { useFlags } from 'launchdarkly-react-client-sdk';

import Badge from '../Badge';

import { MAX_NOTIFICATIONS } from './constants';
import styles from './styles.module.scss';
import { ReactComponent as BellIcon } from './assets/ic_bell.svg';
import { UserType } from '../../../../types/User';

interface Props {
  showComponent: boolean;
  setShowComponent: Dispatch<SetStateAction<boolean>>;
  refTo: React.RefObject<HTMLInputElement>;
}

function NotificationPanel({ showComponent, setShowComponent, refTo }: Props) {
  const { newNotification: notificationFlag } = useFlags();
  const { t } = useTranslation();
  const notificationsDispatch = useDispatch();
  const { addToast } = useToasts();
  const dispatchSidebar = useDispatchSidebar();
  const user = useSelectorUser(state => state.user!);

  const {
    unreadNotifications,
    error,
    notifications,
    unreadNotificationsAreLoading,
    totalCountUnread
  } = useSelector(state => ({
    unreadNotifications: state.unreadNotifications,
    error: state.error,
    notifications: state.notifications,
    unreadNotificationsAreLoading: state.unreadNotificationsAreLoading,
    totalCountUnread: state.totalCountUnread
  }));
  const fixedHeader = useSelectorSidebar(state => state.fixedHeader);

  const handleClosePanel = () => {
    setShowComponent(false);
    if (fixedHeader) {
      dispatchSidebar(actionCreatorsSidebar.fixedHeader());
    }
  };

  const [, , , readNotificationRequest] = useLazyRequest({
    request: (id: number) => readNotification(id, user.id || 0, user.userType || UserType.CLIENT, true),
    withRequSuccess: (response, id) => {
      if (response) {
        const newNotifications = unreadNotifications.filter(
          (notification: { id: number }) => notification.id !== id
        );

        const oldNotifications = unreadNotifications.filter(
          (notification: { id: number }) => notification.id === id
        );
        notificationsActionCreators.readNotification(newNotifications, oldNotifications);
      }
    }
  });
  const [, , , notificationWithoutAlertReq] = useLazyRequest({
    request: (body: IPostNotificationWithoutAlert) =>
      notificationWithoutAlert(user.id || 0, user.userType || UserType.CLIENT, body),
    withRequSuccess: (response, body) => {
      if (body) {
        const newNotifications = unreadNotifications.filter(
          (notification: { id: number }) => notification.id !== body.notificationId
        );

        const oldNotifications = unreadNotifications.filter(
          (notification: { id: number }) => notification.id === body.notificationId
        );
        notificationsActionCreators.readNotification(newNotifications, oldNotifications);
      }
    }
  });

  const onReadNotification = (id: number) => {
    readNotificationRequest(id);
    const { newNotifications, oldNotifications, count } = getNewNotifications(
      notifications,
      unreadNotifications,
      id
    );
    notificationsDispatch(
      notificationsActionCreators.setUnreadNotifications({
        notifications: newNotifications,
        totalCount: totalCountUnread - count
      })
    );
    notificationsDispatch(notificationsActionCreators.readNotification(newNotifications, oldNotifications));
  };

  const readNotificationWithoutAlert = (id: number) => {
    const bodyRequest = {
      notificationId: id,
      userId: user.id!.toString(),
      userType: user.userType!,
      userTypeId: user.userTypeId!.toString(),
      read: true
    };
    notificationWithoutAlertReq(bodyRequest);
    const { newNotifications, oldNotifications, count } = getNewNotifications(
      notifications,
      unreadNotifications,
      id
    );
    notificationsDispatch(
      notificationsActionCreators.setUnreadNotifications({
        notifications: newNotifications,
        totalCount: totalCountUnread - count
      })
    );
    notificationsDispatch(notificationsActionCreators.readNotification(newNotifications, oldNotifications));
  };

  const handleGoToActivityCenter = () => handleClosePanel();

  useEffect(() => {
    if (error) {
      addToast(<Alert>{t('OptionPanel:error')}</Alert>, {
        appearance: 'error'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <div
      className={cn(
        'column',
        styles.optionPanel,
        { [styles.noDisplay]: !showComponent },
        { [styles.scrollableY]: unreadNotifications?.length > 1 },
        { [styles.widthFlag]: notificationFlag }
      )}
      ref={refTo}
    >
      <div
        className={`row space-between middle ${!notificationFlag && 'm-bottom-1'} ${
          notificationFlag ? styles.headerFlag : styles.header
        }`}
      >
        {showComponent && (
          <div>
            <span
              className={`${styles.titleNotifications} ${notificationFlag ? styles.titleSize : 'big-text'}`}
            >
              {`${t('OptionPanel:notifications')} `}
              {notificationFlag && <p className={styles.titleDontRead}>{t('OptionPanel:unread')}</p>}
            </span>
          </div>
        )}
        <Badge className={styles.badge} numberClassName={styles.unreadNotifications} />
        {notificationFlag ? (
          <div className={styles.showAllNClose}>
            <Link
              aria-label="to-activity-center"
              className={styles.showAll}
              to={PATHS.activityCenter}
              onClick={handleGoToActivityCenter}
            >
              {t('OptionPanel:seeAll')}
            </Link>
            <Tooltip content={t('OptionPanel:close')} position="left center">
              <div>
                <i className={`icon-ic-close ${styles.iconPanel}`} onClick={handleClosePanel} />
              </div>
            </Tooltip>
          </div>
        ) : (
          <Tooltip content={t('OptionPanel:close')} position="left center">
            <div>
              <i className={`icon-ic-close ${styles.iconPanel}`} onClick={handleClosePanel} />
            </div>
          </Tooltip>
        )}
      </div>
      {!notificationFlag && <hr className={styles.lineSeparator} />}
      {unreadNotificationsAreLoading ? (
        <Loading type="fiber-place" />
      ) : (
        <div
          className={`column space-between ${
            notificationFlag ? styles.areaNotificationsWrapperNoPadding : styles.areaNotificationsWrapper
          }`}
        >
          {unreadNotifications?.length > 0 ? (
            <div>
              {unreadNotifications.slice(0, MAX_NOTIFICATIONS).map((notification: NotificationType) => (
                <Notification
                  createdAt={notification.createdAt}
                  description={notification.description}
                  id={notification.id}
                  key={notification.id}
                  read={notification.read}
                  readNotification={() =>
                    notification.alertId
                      ? onReadNotification(notification.alertId)
                      : readNotificationWithoutAlert(notification.id)
                  }
                  title={notification.title}
                  type={notification.type}
                  notificationFlag={!notificationFlag}
                  url={notification.url}
                  actionType={notification.actionType}
                />
              ))}
            </div>
          ) : (
            <div className={`column center middle ${styles.emptyNotifications}`}>
              <BellIcon className={`m-bottom-3 ${styles.imageEmptyNotifications}`} />
              <span className={`text m-bottom-3 ${styles.textEmptyNotifications}`}>
                {t(`OptionPanel:${error ? 'error' : 'noNotifications'}`)}
              </span>
            </div>
          )}
          {!notificationFlag && (
            <Link
              aria-label="to-activity-center"
              className={`${styles.footer} center middle`}
              to={PATHS.activityCenter}
              onClick={handleGoToActivityCenter}
            >
              <span className={`big-text m-bottom-3 ${styles.showMore}`}>
                {unreadNotifications?.length > 0
                  ? t('OptionPanel:viewMoreNotifications')
                  : t('OptionPanel:noNotificationsText')}
              </span>
              <hr className={styles.lineEnd} />
            </Link>
          )}
        </div>
      )}
    </div>
  );
}

export default NotificationPanel;

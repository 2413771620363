import api from 'config/api';
import { Nullable } from 'types/Global';
import { UserType } from 'types/User';
import { getCurrentLanguage, LANGUAGES } from 'utils/i18n';
import { IPostNotificationWithoutAlert } from '../types/Notifications';

export const notificationsBaseURL = `${api.getBaseURL() || ''}`;

// export const notificationsBaseURL = 'http://localhost:3002';

export interface NotificationsParams {
  userId?: number;
  userType?: UserType;
  read?: Nullable<boolean>;
  page?: number;
  size?: number;
  lang?: string;
}

export const getLanguage = (languageId: string | undefined) => {
  if (languageId) {
    return languageId === LANGUAGES[0] ? 'ESP' : 'ENG';
  }
  return getCurrentLanguage() === LANGUAGES[0] ? 'ESP' : 'ENG';
};
// TODO: Remove this when we added a LoadMore button in ActivityCenter screen
export const UNREAD_NOTIFICATIONS_SIZE = 4;

/**
 * It will return all the notifications
 *
 * @remarks
 * By default activity center it's set on true.
 *
 * @param userType - String which represent the user's ID rol
 * @param read - Boolean for read(true) or unread(false) notification
 * @param page - Number
 * @param size - Number
 * @param languageId - String could be 'es' or 'en' which affect the language of the notifications
 * @returns notifications
 */
export const getNotifications = ({ userType, userId, read, page, size, lang }: NotificationsParams) =>
  api.get(`${notificationsBaseURL}/notification`, {
    userType,
    userId,
    read,
    page,
    size,
    lang: getLanguage(lang)
  });

export const readNotification = (id: number, userId: number, userType: UserType, read: boolean) =>
  api.put(`${notificationsBaseURL}/notification/${id}?user_id=${userId}&user_type=${userType}`, { read });

export const notificationWithoutAlert = (
  userId: number,
  userType: UserType,
  body: IPostNotificationWithoutAlert
) =>
  api.post(`${notificationsBaseURL}/notifications/alert?user_id=${userId}&user_type=${userType}`, {
    ...body
  });

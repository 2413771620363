import { Nullable } from 'types/Global';
import { NotificationsType, NotificationType } from 'types/Notifications';

export interface NotificationsState {
  notifications: NotificationType[];
  unreadNotifications: NotificationType[];
  count?: number;
  totalPages?: Nullable<number>;
  totalCount?: number;
  totalCountUnread: number;
  previousPage?: number;
  currentPage?: number;
  nextPage?: number;
  unreadNotificationsAreLoading?: boolean;
  error?: boolean;
}

interface UnreadNotifications {
  notifications: NotificationType[];
  totalCount: number;
}

export const INITIAL_STATE: NotificationsState = {
  notifications: [],
  unreadNotifications: [],
  count: 0,
  totalPages: 0,
  totalCount: 0,
  totalCountUnread: 0,
  previousPage: 0,
  currentPage: 0,
  nextPage: 0,
  unreadNotificationsAreLoading: false,
  error: false
};

export enum ActionTypes {
  SET_NOTIFICATIONS = 'SET_NOTIFICATIONS',
  SET_UNREAD_NOTIFICATIONS = 'SET_UNREAD_NOTIFICATIONS',
  SET_IS_LOADING = 'SET_IS_LOADING',
  READ_NOTIFICATION = 'READ_NOTIFICATION',
  SET_ERROR = 'SET_ERROR'
}

interface SetNotifications {
  type: ActionTypes.SET_NOTIFICATIONS;
  payload: NotificationsType;
}

interface SetUnreadNotifications {
  type: ActionTypes.SET_UNREAD_NOTIFICATIONS;
  payload: UnreadNotifications;
}

interface ReadNotification {
  type: ActionTypes.READ_NOTIFICATION;
  payload: { newNotifications: NotificationType[]; oldNotifications: NotificationType[] };
}

interface SetIsLoading {
  type: ActionTypes.SET_IS_LOADING;
}

interface SetError {
  type: ActionTypes.SET_ERROR;
  payload: boolean;
}

export type Action = SetNotifications | SetUnreadNotifications | ReadNotification | SetIsLoading | SetError;

export const actionCreators = {
  setNotifications: (notifications: NotificationsType): SetNotifications => ({
    type: ActionTypes.SET_NOTIFICATIONS,
    payload: notifications
  }),
  setUnreadNotifications: ({ notifications, totalCount }: UnreadNotifications): SetUnreadNotifications => ({
    type: ActionTypes.SET_UNREAD_NOTIFICATIONS,
    payload: { notifications, totalCount }
  }),
  setIsLoading: (): SetIsLoading => ({
    type: ActionTypes.SET_IS_LOADING
  }),
  readNotification: (
    newNotifications: NotificationType[],
    oldNotifications: NotificationType[]
  ): ReadNotification => ({
    type: ActionTypes.READ_NOTIFICATION,
    payload: { newNotifications, oldNotifications }
  }),
  setError: (error: boolean): SetError => ({
    type: ActionTypes.SET_ERROR,
    payload: error
  })
};

export const reducer = (state: NotificationsState, action: Action): NotificationsState => {
  switch (action.type) {
    case 'SET_NOTIFICATIONS': {
      const { notifications, currentPage, nextPage, totalPages } = action.payload;
      return {
        ...state,
        notifications,
        currentPage,
        nextPage,
        totalPages
      };
    }
    case 'SET_UNREAD_NOTIFICATIONS': {
      const { notifications, totalCount } = action.payload;
      return {
        ...state,
        unreadNotifications: notifications,
        totalCountUnread: totalCount
      };
    }
    case 'SET_IS_LOADING': {
      return {
        ...state,
        unreadNotificationsAreLoading: !state.unreadNotificationsAreLoading
      };
    }
    case 'READ_NOTIFICATION': {
      const { newNotifications, oldNotifications } = action.payload;
      return {
        ...state,
        unreadNotifications: newNotifications,
        notifications: oldNotifications
      };
    }
    case 'SET_ERROR': {
      return {
        ...state,
        error: action.payload
      };
    }
    default: {
      return state;
    }
  }
};

import { Nullable } from 'types/Global';

import { ActivityStatus } from './Activity';
import { UserType } from './User';

export enum ActionTypeNotifications {
  ISSUE = 'issue',
  SHOW_FEEDBACK = 'showFeedback',
  VALIDATE_ORDER = 'validateOrder'
}

export interface NotificationType {
  url: string;
  actionType: ActionTypeNotifications;
  alertId: number;
  id: number;
  orderId: string;
  userIdSender: string;
  typeIdSender: string;
  userIdOwner: string;
  typeIdOwner: string;
  itemNum: number;
  read: boolean;
  language: string;
  title: string;
  description: string;
  type: ActivityStatus;
  createdAt: string;
  updatedAt: string;
  action?: {
    id: string;
    type: ActionTypeNotifications;
    url: string;
  };
}

export interface NotificationsType {
  notifications: NotificationType[];
  unreadNotifications: NotificationType[];
  count: number;
  totalPages: Nullable<number>;
  totalCount: number;
  previousPage: number;
  currentPage: number;
  nextPage: number;
}

export interface IPostNotificationWithoutAlert {
  notificationId: number;
  userId: string;
  userType: UserType;
  userTypeId: string;
  read: boolean;
}

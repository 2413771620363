/* eslint-disable */
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import cn from 'classnames';
import { useMsal } from '@azure/msal-react';
import { useAuth0 } from '@auth0/auth0-react';

import { useSelector as useSelectorSidebar } from 'contexts/SidebarContext';
import { useSelector } from 'contexts/UserContext';
import { Nullable } from 'types/Global';
import { User } from 'types/User';
import Header from 'components/Header';
import Sidebar from 'components/Sidebar';
// import useLogout from 'hooks/useLogout';
import { getSavedUser, removeSavedUser } from 'utils/session';
import { ModuleCSAT } from 'modules/CSAT/views/ModuleCSAT/ModuleCSAT';

import { sidebarItems, isLoginScreens } from './constants';
import styles from './styles.module.scss';

interface ChildrenProps {
  onClick?: () => void;
}

interface Props {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<ChildrenProps>;
  transparentHeader?: boolean;
  withHeader?: boolean;
  withSidebarTabletAndMobile?: boolean;
  screen: string;
  showCsat?: boolean;
  showBgImg?: boolean;
}

function Layout({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  component: Component,
  transparentHeader = false,
  withSidebarTabletAndMobile = true,
  withHeader = true,
  screen,
  showCsat = true,
  showBgImg = true,
  ...routeProps
}: Props & RouteComponentProps<any>) {
  const { instance } = useMsal();
  const { logout } = useAuth0();
  const savedUser = getSavedUser();
  const handleLogout = () => {
    // this flag is used in the App/index.tsx
    window.localStorage.setItem('logout', 'true');
    if (savedUser?.isAzure) {
      instance.logoutRedirect();
    } else {
      removeSavedUser();
      logout({ returnTo: window.location.origin });
    }
  };
  const showSidebar = useSelectorSidebar(state => state.showSidebar);
  const user: Nullable<User> = useSelector(state => state.user);
  const [sidebarMobileIsOpen, setSidebarMobileIsOpen] = useState(false);

  const onClickSidebarMobile = () => setSidebarMobileIsOpen(!sidebarMobileIsOpen);

  return (
    <div className="row full-width">
      <Sidebar
        onClickSidebarMobile={onClickSidebarMobile}
        sidebarIsOpen={showSidebar}
        sidebarMobileIsOpen={sidebarMobileIsOpen}
        withSidebarTabletAndMobile={withSidebarTabletAndMobile}
        user={user}
        sidebarItems={sidebarItems(screen, handleLogout)}
      />
      <div
        className={cn(
          [styles.containerContent],
          'full-width ',
          { ['bg-img-wrapper']: showBgImg },
          { [styles.sidebarOpen]: showSidebar },
          { [styles.sidebarOnlyMobile]: withSidebarTabletAndMobile }
        )}
      >
        {withHeader && (
          <Header
            onClick={onClickSidebarMobile}
            hamburger={sidebarMobileIsOpen}
            transparentHeader={transparentHeader}
          />
        )}
        <div className={cn({ [styles.componentContainer]: !isLoginScreens(screen) })}>
          <Component {...routeProps} onClick={onClickSidebarMobile} />
        </div>
        {user?.id && showCsat && <ModuleCSAT user={user} />}
      </div>
    </div>
  );
}

export default Layout;

/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { NotificationType } from 'types/Notifications';

export const isMarkAsRead = (unreadNotifications: NotificationType[], id: number, alertId: number) =>
  unreadNotifications.find((notification: { id: number }) => notification.id === id) !== undefined;

export const getNewNotifications = (
  notifications: NotificationType[],
  unreadNotifications: NotificationType[],
  notificationId: number
) => {
  let newNotifications: NotificationType[] = [];
  let oldNotifications: NotificationType[] = [];
  let count = 0;
  if (isMarkAsRead(unreadNotifications, notificationId, 0)) {
    newNotifications = unreadNotifications.filter(
      (notification: { id: number }) => notification.id !== notificationId
    );
    oldNotifications = [...notifications];
    oldNotifications.push(
      unreadNotifications.filter((notification: { id: number }) => notification.id === notificationId)[0]
    );
    count = 1;
  } else {
    oldNotifications = notifications.filter(
      (notification: { id: number }) => notification.id !== notificationId
    );
    newNotifications = [...unreadNotifications];
    newNotifications.push(
      notifications.filter((notification: { id: number }) => notification.id === notificationId)[0]
    );
    count = -1;
  }
  return { newNotifications, oldNotifications, count };
};

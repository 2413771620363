import mixpanel from 'mixpanel-browser';
import { mixPanelDictionary } from './mixPanelDictionary';

const translateModule: { [key: string]: string } = {
  '/account_settings': 'Configuración de usuario',
  '/activity_center': 'Notificaciones',
  '/admin': 'Administrador Fiber Place',
  '/admin/csat': 'Submódulo CSAT',
  '/admin/csat/create': 'Creación de CSAT',
  '/articles': 'Artículos',
  '/certificates': 'Certificados',
  '/orders/create': 'Creación de pedido',
  '/users/create': 'Creación de usuario',
  '/creditStatus': 'Estatus crediticio',
  '/cycles': 'Ciclos productivos',
  '/documents': 'Documentos',
  '/helpCenter': 'Centro de ayuda',
  '/': 'Home',
  '/home': 'Home',
  '/login': 'Login',
  '/orders': 'Pedidos',
  '/stockControl': 'Control de stock',
  '/tracking': 'Tracking',
  '/users': 'Usuarios'
};

export const trackModuleEventMixPanel = (module: string) => {
  const moduleOut = module[0] === '/' ? module : `/${module}`;

  mixpanel.track('Module Visit', {
    moduleName: translateModule[moduleOut]
  });
};
export const trackEventMixPanel = (event: string) => {
  const eventOut = mixPanelDictionary[event as keyof typeof mixPanelDictionary] ?? 'Evento Desconocido';

  mixpanel.track('Evento', {
    type: eventOut
  });
};

/* eslint-disable max-lines */
import { lazy } from 'react';

import { UserPermissions } from 'config/permissions';
import { UserTypeId } from 'types/User';

import PATHS from './paths';

const AccountSettings = lazy(() => import('screens/AccountSettings'));
const PackingList = lazy(() => import('screens/OrderDetail/components/OrderInfo/components/PackingList'));
const OrderDetail = lazy(() => import('screens/OrderDetail'));
const Orders = lazy(() => import('screens/Orders'));
const NewOrders = lazy(() => import('modules/newOrders').then(module => ({ default: module.NewOrders })));
const QualityCertificatesAVG = lazy(() => import('screens/QualityCertificatesAVG'));
const QualityCertificates = lazy(() => import('screens/QualityCertificates'));
const Login = lazy(() => import('screens/Login'));
const Notifications = lazy(() => import('screens/Notifications'));
const UserList = lazy(() => import('screens/UserList'));
const CreateUser = lazy(() => import('screens/CreateUser'));
const CreateOrder = lazy(() => import('screens/CreateOrder'));
const EditUser = lazy(() => import('screens/EditUser'));
const ArticleDetail = lazy(() => import('screens/ArticleDetail'));
const DocumentsList = lazy(() => import('modules/documents/views/ListDocumentsBy/ListDocumentsBy'));
const DocumentsView = lazy(() => import('modules/documents/views/DocumentView/DocumentView'));
const Articles = lazy(() => import('screens/Articles'));
const Certificates = lazy(() => import('screens/Certificates'));
const StockControl = lazy(() => import('screens/StockControl'));
const CreditStatus = lazy(() => import('screens/CreditStatus'));
const DetailCreditStatus = lazy(() => import('modules/creditStatus/views/DetailCreditStatus'));
const Documents = lazy(() => import('screens/Documents'));
const ProductionCycles = lazy(() => import('screens/ProductionCycles'));
const EditProductionCycles = lazy(
  () => import('screens/ProductionCycles/screens/EditProductionCycles/EditProductionCycles')
);
const HomeScreen = lazy(() => import('screens/Home'));
const HelpCenterScreen = lazy(() => import('screens/HelpCenter'));
const TrackingScreen = lazy(() => import('screens/Tracking'));
const TrackingDetailScreen = lazy(() => import('modules/tracking/views/TrackingDetail'));
const Admin = lazy(() => import('screens/Admin'));
const AdminCSAT = lazy(() => import('modules/admin/submodules/CSAT/HomeCSAT'));
const AdminCSATCreate = lazy(() => import('modules/admin/submodules/CSAT/components/CreateForm/CreateForm'));
const AdminModal = lazy(() => import('modules/admin/submodules/InformativeModal/HomeModal'));
const AdminModalCreate = lazy(
  () => import('modules/admin/submodules/InformativeModal/components/CreateForm/CreateForm')
);
const EUDR = lazy(() => import('modules/EUDR'));

export const MAIN_PUBLIC_PATH = PATHS.login;
export const MAIN_PRIVATE_PATH = PATHS.homeScreen;

export enum SCREENS {
  ACCOUNT_SETTINGS = 'accountSettings',
  ACTIVITY_CENTER = 'activityCenter',
  ADMIN = 'admin',
  ADMIN_CSAT = 'adminCsat',
  ADMIN_CSAT_CREATE = 'adminCsatCreate',
  ADMIN_MODAL = 'adminCsat',
  ADMIN_MODAL_CREATE = 'adminCsatCreate',
  ADMIN_MODAL_UPDATE = 'adminCsatUpdate',
  ARTICLES = 'articles',
  ARTICLE_DETAIL = 'articleDetail',
  CREATE_ORDER = 'createOrder',
  CREATE_USER = 'createUser',
  EUDR = 'eudr',
  LOGIN = 'login',
  PACKING_LIST = 'packingList',
  ORDER_DETAIL = 'orderDetail',
  ORDERS = 'orders',
  NEW_ORDERS = 'newOrders',
  EDIT_USER = 'editUser',
  USER_LIST = 'userList',
  CERTIFICATES = 'certificates',
  STOCK_CONTROL = 'stockControl',
  CREDIT_STATUS = 'creditStatus',
  CREDIT_STATUS_DETAIL = 'creditStatusDetail',
  DOCUMENTS = 'documents',
  DOCUMENTS_LIST = 'documentsList',
  DOCUMENTS_VIEW = 'documentsView',
  PRODUCTION_CYCLE = 'productionCycle',
  EDIT_PRODUCTION_CYCLE = 'editProductionCycle',
  QUALITY_CERTIFICATES_AVG = 'QualityCertificatesAVG',
  QUALITY_CERTIFICATES = 'QualityCertificates',
  HOME_SCREEN = 'homeScreen',
  HELP_CENTER_SCREEN = 'helpCenterScreen',
  TRACKING_SCREEN = 'trackingScreen',
  TRACKING_DETAIL_SCREEN = 'trackingScreen'
}

export enum TAG {
  CMPC,
  SUPERUSER,
  AGENT,
  CLIENT,
  PUBLIC,
  PRIVATE,
  AUTH
}

export enum PermissionDetail {
  NO_PERMISSION = '',
  CREDIT_STATUS = 'can_view_credit_status',
  CONTROL_STOCK = 'can_view_stock_controller',
  TRAKING = 'can_view_tracking',
  ADMIN = 'can_view_admin'
}

/* When adding routes, add them ABOVE the Home route
 * or it will redirect you to incorrect screens */
export const ROUTES = [
  // {
  //   exact: false,
  //   path: PATHS.registration,
  //   component: Registration,
  //   title: 'Routes:registrationTitle',
  //   description: 'Routes:registrationDescription',
  //   permission: PermissionDetail.NO_PERMISSION
  // },
  {
    exact: true,
    path: PATHS.createUser,
    component: CreateUser,
    title: 'Routes:createUserTitle',
    description: 'Routes:createUserDescription',
    tags: [TAG.PRIVATE],
    roles: [UserTypeId.SUPERUSER],
    withSidebarTabletAndMobile: false,
    withHeader: true,
    screen: SCREENS.CREATE_USER,
    permission: PermissionDetail.NO_PERMISSION
  },
  {
    exact: true,
    path: PATHS.homeScreen,
    component: HomeScreen,
    title: 'Routes:homeScreen',
    description: 'Routes:homeScreenDescription',
    tags: [TAG.PRIVATE],
    withSidebarTabletAndMobile: false,
    withHeader: true,
    screen: SCREENS.HOME_SCREEN,
    permission: PermissionDetail.NO_PERMISSION
  },
  {
    exact: true,
    path: PATHS.helpCenter,
    component: HelpCenterScreen,
    title: 'Routes:helpCenterScreen',
    description: 'Routes:helpCenterScreenDescription',
    tags: [TAG.PRIVATE],
    withSidebarTabletAndMobile: false,
    withHeader: true,
    screen: SCREENS.HELP_CENTER_SCREEN,
    permission: PermissionDetail.NO_PERMISSION
  },
  {
    exact: true,
    path: PATHS.login,
    component: Login,
    title: 'Routes:loginTitle',
    description: 'Routes:loginDescription',
    tags: [TAG.AUTH],
    withSidebarTabletAndMobile: true,
    withHeader: false,
    screen: SCREENS.LOGIN,
    permission: PermissionDetail.NO_PERMISSION
  },
  {
    exact: true,
    path: PATHS.accountSettings,
    component: AccountSettings,
    title: 'Routes:accountSettingsTitle',
    description: 'Routes:accountSettingsDescription',
    tags: [TAG.PRIVATE],
    withSidebarTabletAndMobile: false,
    withHeader: true,
    screen: SCREENS.ACCOUNT_SETTINGS,
    permission: PermissionDetail.NO_PERMISSION
  },
  {
    exact: true,
    path: PATHS.packingList,
    component: PackingList,
    title: 'Routes:packingListTitle',
    description: 'Routes:packingListDescription',
    tags: [TAG.PRIVATE],
    transparentHeader: true,
    withSidebarTabletAndMobile: false,
    withHeader: true,
    screen: SCREENS.PACKING_LIST,
    permission: PermissionDetail.NO_PERMISSION
  },
  {
    exact: true,
    path: PATHS.orderDetail,
    component: OrderDetail,
    title: 'Routes:orderDetailTitle',
    description: 'Routes:orderDetailDescription',
    tags: [TAG.PRIVATE],
    transparentHeader: true,
    withSidebarTabletAndMobile: false,
    withHeader: true,
    screen: SCREENS.ORDER_DETAIL,
    permission: PermissionDetail.NO_PERMISSION
  },
  {
    exact: true,
    path: PATHS.activityCenter,
    component: Notifications,
    title: 'Routes:notificationsTitle',
    description: 'Routes:notificationsDescription',
    tags: [TAG.PRIVATE],
    withSidebarTabletAndMobile: false,
    withHeader: true,
    screen: SCREENS.ACTIVITY_CENTER,
    permission: PermissionDetail.NO_PERMISSION
  },
  {
    exact: true,
    path: PATHS.users,
    component: UserList,
    title: 'Routes:userListTitle',
    description: 'Routes:userListDescription',
    tags: [TAG.PRIVATE],
    roles: [UserTypeId.SUPERUSER],
    withSidebarTabletAndMobile: false,
    withHeader: true,
    screen: SCREENS.USER_LIST,
    permission: PermissionDetail.NO_PERMISSION
  },
  {
    exact: true,
    path: PATHS.orders,
    component: Orders,
    title: 'Routes:ordersTitle',
    description: 'Routes:ordersDescription',
    tags: [TAG.PRIVATE],
    transparentHeader: true,
    withSidebarTabletAndMobile: false,
    withHeader: true,
    screen: SCREENS.ORDERS,
    permission: PermissionDetail.NO_PERMISSION
  },
  {
    exact: true,
    path: PATHS.newOrders,
    component: NewOrders,
    title: 'Routes:ordersTitle',
    description: 'Routes:ordersDescription',
    tags: [TAG.PRIVATE],
    transparentHeader: true,
    withSidebarTabletAndMobile: false,
    withHeader: false,
    showBgImg: false,
    screen: SCREENS.NEW_ORDERS,
    permission: PermissionDetail.NO_PERMISSION
  },
  {
    exact: true,
    path: `${PATHS.createOrder}*`,
    component: CreateOrder,
    title: 'Routes:createOrderTitle',
    description: 'Routes:createOrderDescription',
    tags: [TAG.PRIVATE],
    transparentHeader: true,
    withSidebarTabletAndMobile: false,
    withHeader: true,
    screen: SCREENS.CREATE_ORDER,
    permission: PermissionDetail.NO_PERMISSION
  },
  {
    exact: true,
    path: PATHS.editUser,
    component: EditUser,
    title: 'Routes:editUserTitle',
    description: 'Routes:editUserDescription',
    tags: [TAG.PRIVATE],
    roles: [UserTypeId.SUPERUSER],
    withSidebarTabletAndMobile: false,
    withHeader: true,
    screen: SCREENS.EDIT_USER,
    permission: PermissionDetail.NO_PERMISSION
  },
  {
    path: PATHS.articles,
    component: Articles,
    title: 'Routes:articles',
    description: 'Routes:articlesDescription',
    tags: [TAG.PRIVATE],
    roles: [UserTypeId.SUPERUSER, UserTypeId.CMPC],
    transparentHeader: true,
    withSidebarTabletAndMobile: false,
    withHeader: true,
    screen: SCREENS.ARTICLES,
    permission: PermissionDetail.NO_PERMISSION
  },
  {
    exact: true,
    path: PATHS.articleDetail,
    component: ArticleDetail,
    title: 'Routes:articlesDetails',
    description: 'Routes:articlesDetailsDescription',
    tags: [TAG.PRIVATE],
    transparentHeader: true,
    withSidebarTabletAndMobile: false,
    withHeader: true,
    screen: SCREENS.ARTICLE_DETAIL,
    permission: PermissionDetail.NO_PERMISSION
  },
  {
    exact: true,
    path: PATHS.certificates,
    component: Certificates,
    title: 'Routes:certificates',
    description: 'Routes:certificatesDescription',
    tags: [TAG.PRIVATE],
    transparentHeader: true,
    withSidebarTabletAndMobile: false,
    withHeader: true,
    screen: SCREENS.CERTIFICATES,
    permission: PermissionDetail.NO_PERMISSION
  },
  {
    exact: true,
    path: PATHS.stockControl,
    component: StockControl,
    title: 'Routes:stockControl',
    description: 'Routes:stockControl',
    tags: [TAG.PRIVATE],
    transparentHeader: true,
    withSidebarTabletAndMobile: false,
    withHeader: true,
    screen: SCREENS.STOCK_CONTROL,
    permission: PermissionDetail.CONTROL_STOCK
  },
  {
    exact: true,
    path: PATHS.tracking,
    component: TrackingScreen,
    title: 'Routes:trackingScreen',
    description: 'Routes:trackingScreen',
    tags: [TAG.PRIVATE],
    transparentHeader: true,
    withSidebarTabletAndMobile: false,
    withHeader: true,
    screen: SCREENS.TRACKING_SCREEN,
    permission: PermissionDetail.TRAKING
  },
  {
    exact: true,
    path: PATHS.trackingDetail,
    component: TrackingDetailScreen,
    title: 'Routes:trackingDetailScreen',
    description: 'Routes:trackingDetailScreen',
    tags: [TAG.PRIVATE],
    transparentHeader: true,
    withSidebarTabletAndMobile: false,
    withHeader: true,
    screen: SCREENS.TRACKING_SCREEN,
    permission: PermissionDetail.TRAKING
  },
  {
    exact: true,
    path: PATHS.creditStatus,
    component: CreditStatus,
    title: 'Routes:creditStatus',
    description: 'Routes:creditStatus',
    tags: [TAG.PRIVATE],
    transparentHeader: true,
    withSidebarTabletAndMobile: false,
    withHeader: true,
    screen: SCREENS.CREDIT_STATUS,
    permission: PermissionDetail.NO_PERMISSION
  },
  {
    exact: true,
    path: PATHS.creditStatusDetail,
    component: DetailCreditStatus,
    title: 'Routes:creditStatusDetail',
    description: 'Routes:creditStatusDetail',
    tags: [TAG.PRIVATE],
    transparentHeader: true,
    withSidebarTabletAndMobile: false,
    withHeader: true,
    screen: SCREENS.CREDIT_STATUS_DETAIL,
    permission: PermissionDetail.NO_PERMISSION
  },
  {
    exact: true,
    path: PATHS.documents,
    component: Documents,
    title: 'Routes:documents',
    description: 'Routes:documents',
    tags: [TAG.PRIVATE],
    transparentHeader: true,
    withSidebarTabletAndMobile: false,
    withHeader: true,
    screen: SCREENS.DOCUMENTS,
    permission: PermissionDetail.NO_PERMISSION
  },
  {
    exact: true,
    path: PATHS.documentsList,
    component: DocumentsList,
    title: 'Routes:documentsList',
    description: 'Routes:documentsList',
    tags: [TAG.PRIVATE],
    transparentHeader: true,
    withSidebarTabletAndMobile: false,
    withHeader: true,
    screen: SCREENS.DOCUMENTS_LIST,
    permission: PermissionDetail.NO_PERMISSION
  },
  {
    exact: true,
    path: PATHS.documentsView,
    component: DocumentsView,
    title: 'Routes:documentsView',
    description: 'Routes:documentsView',
    tags: [TAG.PRIVATE],
    transparentHeader: true,
    withSidebarTabletAndMobile: false,
    withHeader: true,
    screen: SCREENS.DOCUMENTS_VIEW,
    permission: PermissionDetail.NO_PERMISSION
  },
  {
    exact: true,
    path: PATHS.qualityCertificatesAVG,
    component: QualityCertificatesAVG,
    title: 'Routes:QualityCertificatesAVG',
    description: 'Routes:QualityCertificatesAVGDescription',
    tags: [TAG.PRIVATE],
    transparentHeader: true,
    withSidebarTabletAndMobile: false,
    withHeader: true,
    screen: SCREENS.QUALITY_CERTIFICATES_AVG,
    permission: PermissionDetail.NO_PERMISSION,
    showCsat: false
  },
  {
    exact: true,
    path: PATHS.qualityCertificates,
    component: QualityCertificates,
    title: 'Routes:QualityCertificates',
    description: 'Routes:QualityCertificatesDescription',
    tags: [TAG.PRIVATE],
    transparentHeader: true,
    withSidebarTabletAndMobile: false,
    withHeader: true,
    screen: SCREENS.QUALITY_CERTIFICATES,
    permission: PermissionDetail.NO_PERMISSION,
    showCsat: false
  },
  {
    exact: true,
    path: PATHS.cycles,
    component: ProductionCycles,
    title: 'Routes:productionCycles',
    description: 'Routes:productionCyclesDescription',
    tags: [TAG.PRIVATE],
    transparentHeader: true,
    withSidebarTabletAndMobile: false,
    withHeader: true,
    screen: SCREENS.PRODUCTION_CYCLE,
    permission: PermissionDetail.NO_PERMISSION
  },
  {
    exact: true,
    path: PATHS.editProductionCycle,
    component: EditProductionCycles,
    title: 'Routes:editProductionCycles',
    description: 'Routes:editProductionCyclesDescription',
    tags: [TAG.PRIVATE],
    transparentHeader: true,
    withSidebarTabletAndMobile: false,
    withHeader: true,
    screen: SCREENS.EDIT_PRODUCTION_CYCLE,
    permission: UserPermissions.CAN_EDIT_PRODUCTIVE_CYCLES
  },
  {
    exact: true,
    path: PATHS.admin,
    component: Admin,
    title: 'Routes:admin',
    description: 'Routes:admin',
    tags: [TAG.PRIVATE],
    transparentHeader: true,
    withSidebarTabletAndMobile: false,
    withHeader: true,
    screen: SCREENS.ADMIN,
    permission: UserPermissions.CAN_VIEW_ADMIN
  },
  {
    exact: true,
    path: PATHS.adminCSAT,
    component: AdminCSAT,
    title: 'Routes:adminCSAT',
    description: 'Routes:adminCSAT',
    tags: [TAG.PRIVATE],
    transparentHeader: true,
    withSidebarTabletAndMobile: false,
    withHeader: true,
    screen: SCREENS.ADMIN_CSAT,
    permission: UserPermissions.CAN_VIEW_ADMIN
  },
  {
    exact: true,
    path: PATHS.adminCSATCreate,
    component: AdminCSATCreate,
    title: 'Routes:adminCSATCreate',
    description: 'Routes:adminCSATCreate',
    tags: [TAG.PRIVATE],
    transparentHeader: true,
    withSidebarTabletAndMobile: false,
    withHeader: true,
    screen: SCREENS.ADMIN_CSAT_CREATE,
    permission: UserPermissions.CAN_VIEW_ADMIN
  },
  {
    exact: true,
    path: PATHS.adminInformativeModal,
    component: AdminModal,
    title: 'Routes:adminModal',
    description: 'Routes:adminModalDescription',
    tags: [TAG.PRIVATE],
    transparentHeader: true,
    withSidebarTabletAndMobile: false,
    withHeader: true,
    screen: SCREENS.ADMIN_MODAL,
    permission: UserPermissions.CAN_VIEW_ADMIN
  },
  {
    exact: true,
    path: PATHS.adminInformativeModalCreate,
    component: AdminModalCreate,
    title: 'Routes:adminModalCreate',
    description: 'Routes:adminModalCreateDescription',
    tags: [TAG.PRIVATE],
    transparentHeader: true,
    withSidebarTabletAndMobile: false,
    withHeader: true,
    screen: SCREENS.ADMIN_MODAL_CREATE,
    permission: UserPermissions.CAN_VIEW_ADMIN
  },
  {
    exact: true,
    path: PATHS.eudr,
    component: EUDR,
    title: 'Routes:eudr',
    description: 'Routes:eudr',
    tags: [TAG.PRIVATE],
    transparentHeader: true,
    withSidebarTabletAndMobile: false,
    withHeader: true,
    screen: SCREENS.EUDR,
    permission: PermissionDetail.NO_PERMISSION
  },
  {
    exact: true,
    path: PATHS.adminInformativeModalUpdate,
    component: AdminModalCreate,
    title: 'Routes:adminModalUpdate',
    description: 'Routes:adminModalUpdateDescription',
    tags: [TAG.PRIVATE],
    transparentHeader: true,
    withSidebarTabletAndMobile: false,
    withHeader: true,
    screen: SCREENS.ADMIN_MODAL_UPDATE,
    permission: UserPermissions.CAN_VIEW_ADMIN
  }
];
